(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);
  document.body.addEventListener('mousemove', handleMouseMove);

  const menuTrigger = document.querySelector('.main-header__menu-trigger');
  const menu = document.querySelector('.main-navigation');
  const menuItems = document.querySelectorAll('.main-navigation__item');
  const cursor = document.querySelector('.cursor');
  const links = document.querySelectorAll('a, button');

  function handleDOMContentLoaded() {
    menuTrigger.addEventListener('click', handleMenu);
    menu.addEventListener('animationend', handleMenuAnimation);

    links.forEach((link) => {
      link.addEventListener('mouseenter', handleMouseEnter);
      link.addEventListener('mouseleave', handleMouseLeave);
    })
  }

  function handleMouseMove(e) {
    cursor.style.left = `${e.clientX - 30}px`;
    cursor.style.top = `${e.clientY - 30}px`;
  }

  function handleMouseEnter() {
    cursor.classList.add('cursor--hover');
  }

  function handleMouseLeave() {
    cursor.classList.remove('cursor--hover');
  }

  function handleMenu(e) {
    e.preventDefault();

    if (menu.classList.contains('main-navigation--show')) {
      menu.classList.remove('main-navigation--show');
      menu.classList.add('main-navigation--disable');
      menuTrigger.textContent = 'Menu';

      menuItems.forEach((el) => {
        el.classList.remove('main-navigation__item--show');
      });
    } else {
      menu.classList.add('main-navigation--show');
      menu.classList.remove('main-navigation--disable');
      menuTrigger.textContent = menuTrigger.getAttribute('data-close');

      menuItems.forEach((el, key) => {
        const delay = (200 * key) + 700;
        setTimeout(() => {
          el.classList.add('main-navigation__item--show');
        }, delay);
      });
    }
  }

  function handleMenuAnimation(e) {
    if (e.animationName === 'fade-out') {
      menu.classList.remove('main-navigation--disable');
    }
  }
})();
